/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
html {
    /*-webkit-text-size-adjust: 100%;  Prevent font scaling in landscape while allowing user zoom */
    /*color: #445931 !important;*/
}
h1 {
    font-size: 1.17em;
}
/******************************************************************************/
/* change leaflet layer switch icon                                           */
/******************************************************************************/
/*.leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
}
.leaflet-control-layers-toggle {
    background-image: url(./dist/res/layers.png);
}*/
/******************************************************************************/
/* shake elements                                                             */
/******************************************************************************/
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake{
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
/******************************************************************************/
/* wb buttons                                                                 */
/******************************************************************************/
.legend {
    width: 50px;
    height: 350px;
    display: block;
    margin-top:5px;
    margin-bottom: 5px;
    padding: 2px;
    /*padding-bottom: 0px;*/
    border-radius: 4px;
    border: none;
    background: rgb(255,255,255);
    box-shadow: 0 6px 14px 0 rgb(0 0 0 / 40%);
}
.legend:hover{
    cursor: pointer;
}
.legend:active, .popB:active, .locB:active, .popB:active {
  /*box-shadow:0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);*/
  transform: translateY(8px);
}
.locB, .popB, .menuB, .subB, .lngB {
    width: 35px;
    height: 35px;
    display: block;
    margin-top:5px;
    margin-bottom: 5px;
    padding: 0px;
    border-radius: 50%;
    border: none;
    /*border: 2px solid rgba(0,0,0,0.2);*/
    /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);*/
    background: rgba(255,255,255,0);
    box-shadow: 0 3px 14px 0 rgb(0 0 0 / 40%);
}
.popB {
    width: 30px;
    height: 30px;
    color: #445931;
    display: inline-block; 
    margin-top:15px;
    margin-right: 10px;
}
.menuB{
    width: 41px;
    height: 41px; 
    margin-top:5px;
    margin-right: 0px;
    margin-left: 5px;
}
.subB, .lngB {
    width: 30px;
    height: 30px;
    color: #445931;
    display: inline-grid; 
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 0px;
}
.shopB{
    width: 35px;
    height: 35px;
    display: block;
    margin-top:5px;
    margin-bottom: 5px;
    margin-left: 6px;
    padding: 0px;
    border-radius: 50%;
    border: none;
    background: none;
    /*box-shadow: 0 3px 14px 0 rgb(0 0 0 / 40%);*/
}
/*.shopB > span img {
    width: 75px;
    height: 75px; 
    padding: 0px;
    
    margin-top:0px;
    margin-left: 0px;
}*/
.locB, .popB, .menuB, .subB, .lngB, .shopB, .confirmB :hover{
    cursor: pointer;
}
.leaflet-top.leaflet-left {
    z-index: 5000;
}
#stpL.fa-circle{
    animation-name: led_blink;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.0, 1.0, 1.0, 1.0);
    animation-iteration-count: infinite;
}
@keyframes led_blink {
  from {color: #445931;}
  to {color: #ffffff }
}
.buttonW {
    width: 108px;
    height: 48px;
    position: relative;
    margin: auto;
    padding: 10px 10px;
    border-radius: 4px;
    border: 2px solid rgba(0,0,0,0.2);
    box-shadow: 0 3px 14px 0 rgb(0 0 0 / 40%);
    background: white;
    color: #445931;
    font-weight: 900;
    display: flex;
    align-items: center; 
 }
 .confirmB {
     font-size: 120%;
    color: #445931;
    background-color: #f2f6ef;
    border: 0.5;
    border-color: #f2f6ef;
    border-radius: 100px;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
 }

.confirmB:hover,
.confirmB:focus { 
    background-color: #445931;
    color: #ffffff;
}

.confirmB:active {
    background: #f2f6ef;
    color: #445931;
}

.confirmB:disabled { 
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}
/******************************************************************************/
/* wb marker popup                                                            */
/******************************************************************************/
.wb_table {
    color: #445931;
    font-weight: bold;
}
.leaflet-container a.leaflet-popup-close-button {
    color: #445931;
}
.leaflet-container a.leaflet-popup-close-button:hover {
    color: #4CAF50;
}
.batt {
    color: #445931;
    display: inline-block;
    position:absolute;
    right:12px;
    bottom:15px;
}
.fa-bolt {
    position:absolute;
    right:9px;
    bottom:3px;
    color:red;
    animation-name: bolt_blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
.fa-battery-empty{
    color:red;
    position:absolute;
    right:9px;
    bottom:3px;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes blink {
  from {color: red;}
  to {color: white;}
}
@keyframes bolt_blink {
  from {color: red;}
  to {color:#445931 ;}
}
/******************************************************************************/
/* wb dialogs charts                                                          */
/******************************************************************************/
.leaflet-control-dialog {
    /*font-family: 'Unispace', sans-serif;*/
    color: #445931;
    z-index: 4096;
}
.wbCharts{
    display: grid;
    gap: 20px;
}
#byday {
    grid-column: 1 / span 3;
    grid-row: 2;
}
.legendChP{
    grid-column: 1/ span 2;
    grid-row: 3;
}
.legendChL{
    grid-column: 3;
    grid-row: 1/ span 2;
}